<nb-card>
    <nb-card-header>
        {{model.header}}
        <nb-action size="medium" class="grid-main-actions">
            <nb-icon [hidden]="! model.hasAdd" class="action-icon" title="Add New" status="info" icon="plus-circle-outline" (click)="onAdd()"></nb-icon> &nbsp;
            <nb-icon [hidden]="! model.hasExport" class="action-icon" title="Export PDF" status="info" icon="arrow-circle-down-outline" (click)="onExport()"></nb-icon>
            <!-- <span>Export</span> -->
          </nb-action>
    </nb-card-header>
    <nb-card-body>
        <ng2-smart-table    class="example-smart-table" [settings]="model.settings" [source]="source"
            (custom)="onCustomAction($event)" (userRowSelect)="selectRow($event)" >
        </ng2-smart-table>
    </nb-card-body>
</nb-card>