import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { category, categoryData, categoryFeatureOrder, categoryFilter, categoryOrder, categoryupdate } from '../../../interfaces/categories/categories.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';
import { star } from '../../../interfaces/stars/stars.models';


@Injectable()
export class categoryService extends categoryData {
 
  


    private readonly apiController: string = EndPointsConfig.CATEGORY.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }

    getAll(filter: categoryFilter): Observable<PaginatedResult<category[]>> {

        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );

    }

    getById(categoryId: string): Observable<category> {
        return this.api.get(`${this.apiController}/${categoryId}`)
            .pipe(
                map(res => res.data)
            )
    }


    updateCategory(categoryId: string, category: categoryupdate): Observable<boolean> {
        return this.api.put(`${this.apiController}/${categoryId}`, category).pipe(
            map(res => res.Success)
        );

    }
    addCategory(category: categoryupdate): Observable<boolean> {
        return this.api.post(`${this.apiController}`, category).pipe(
            map(res => res.Success)
        );
    }

    deleteCategory(categoryId: number): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${categoryId}`, {}).pipe(
            map(res => res.Success)
        );
    }
    setCategoriesOrder(categoryOrder: categoryOrder): Observable<boolean> {
        return this.api.post(`${this.apiController}/${EndPointsConfig.CATEGORY.ENDPOINTS.order}`, categoryOrder).pipe(
            map(res => res.Success)
        ); 
    }
    getCategoryFeaturedStars(categoryId: string,filter: categoryFilter): Observable<star[]> {
         return this.api.get(`${this.apiController}/${categoryId}/${EndPointsConfig.CATEGORY.ENDPOINTS.featured}`, { params: filter })
        .pipe(
            map(res => res.data)
        )

    }
 
    setCategoriesStarsOrder(categoryId: number, starsOrder: categoryFeatureOrder): Observable<boolean> {
        return this.api.post(`${this.apiController}/${categoryId}/${EndPointsConfig.CATEGORY.ENDPOINTS.featured}`, starsOrder).pipe(
            map(res => res.Success)
        );     }


}