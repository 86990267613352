 import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';
import { countriesData, country, countryFilter, countryupdate } from '../../../interfaces/country/countries.models';
  

@Injectable()
export class countriesService extends  countriesData {
   
  


    private readonly apiController: string = EndPointsConfig.COUNTRY.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    } 
    getCountries(): Observable<country[]> {
        return this.api.get(`${EndPointsConfig.COUNTRY.CONTROLLER}`).pipe(
            map(res => res.data),
        );
    }




  

    getAll(filter: countryFilter): Observable<PaginatedResult<country[]>> {

        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );

    }

    getById(countryId: string): Observable<country> {
        return this.api.get(`${this.apiController}/${countryId}`)
            .pipe(
                map(res => res.data)
            )
    }


    updatecountry(countryId: string, country: countryupdate): Observable<boolean> {
        return this.api.put(`${this.apiController}/${countryId}`, country).pipe(
            map(res => res.Success)
        );

    }
    addcountry(country: countryupdate): Observable<boolean> {
        return this.api.post(`${this.apiController}`, country).pipe(
            map(res => res.Success)
        );
    }

    deletecountry(countryId: number): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${countryId}`, {}).pipe(
            map(res => res.Success)
        );
    } 
  


}