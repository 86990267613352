import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { requestsreport, requestsreportData, requestsreportFilter } from '../../../interfaces/requestsreport/requestsreport.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';


@Injectable()
export class requestsreportService extends requestsreportData {
   
   

    
    private readonly apiController: string =  EndPointsConfig.reports.CONTROLLER +'/'+EndPointsConfig.reports.ENDPOINTS.requests.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }
    export(filter: requestsreportFilter): Observable<requestsreport[]> {
        return this.api.get(`${this.apiController}/${EndPointsConfig.reports.ENDPOINTS.requests.SUB.EXPORT}`, { params: filter }).pipe(
            map(res => res.data),
        );
    
    }


    getrequestsreports(filter: requestsreportFilter): Observable<PaginatedResult<requestsreport[]>> {
        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );
    }

    getById(requestsreportId: string): Observable<requestsreport> {
        return this.api.get(`${this.apiController}/${requestsreportId}`)
            .pipe(
                map(res => res.data)
            )
    }

    









}