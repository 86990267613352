import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

export interface starsreport {
    starName: string;
    starEmail: string;
    starMobile: string;
    requestsCount: number;
    totalPayment: number;
    categories: string;
}

 


export interface starsreportFilter extends PageFilter {
    FromDate?: string;
    ToDate?:string;
    StarId?:string;
    PageNumber?:string;
    PageSize?:string;

}


export abstract class starsreportData {
     
    abstract getstarsreports(filter: starsreportFilter): Observable<PaginatedResult<starsreport[]>>;
    abstract export(filter: starsreportFilter) : Observable<starsreport[]>;


 }