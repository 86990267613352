import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, retry, tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toastrService: NbToastrService, private router: Router) {
    }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (this.hasIgnoredAPIRequest(request)) {
            return next.handle(request);
        }

        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    let userMsg = 'Something wen wrong, please try again later or contact your administrator if problem presists';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                         userMsg = error.error.errors || userMsg; // return Business error msg returned from API

                        if (error.status == 404)
                            this.router.navigateByUrl('/pages/miscellaneous/404');
                        else
                            errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                    }

                    console.log(errorMessage);
                    this.showErrorMsg(userMsg);
                    return EMPTY;
                }),
                filter(event => event instanceof HttpResponse),
                tap((event: HttpResponse<any>) => {
                    if (event.body && event.body.Success === false) {
                        let errorMsg = event.body.Message || 'Something wen wrong, please try again later or contact your administrator if problem presists';
                        this.showErrorMsg(errorMsg);
                    }
                })
            )
    }

    showErrorMsg(msg: string) {
        this.toastrService.danger(msg, 'Error!', { destroyByClick: true, duration: 5000 });
    }

    hasIgnoredAPIRequest(req: HttpRequest<any>): boolean {
        return ['/login', '/refresh-token']
            .some(url => req.url.includes(url));
    }
}