import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { CustomerAccountData, CustomerAccountDetails, CustomersAccountsFilter, CustomersAccountsListItem } from '../../../interfaces/customer-account/customer-account.models';
import { CustomerAccountApi } from '../api/customer-accounts.api';


@Injectable()
export class CustomersAccountsService extends CustomerAccountData {

    constructor(private api: CustomerAccountApi) {
        super();
    }

    getAll(filter: CustomersAccountsFilter): Observable<PaginatedResult<CustomersAccountsListItem>> {
        return this.api.getAll(filter)
            .pipe(
                map(res => res.data),
            );
    }

    getById(customerAccountId: string): Observable<CustomerAccountDetails> {
        return this.api.getById(customerAccountId)
            .pipe(
                map(res => res.data)
            )
    }

    changeStatus(customerAccountId: string): Observable<boolean> {
        return this.api.changeStatus(customerAccountId)
            .pipe(
                map(res => res.Success)
            );
    }

    deleteAccount(customerAccountId: string): Observable<boolean> {
        return this.api.deleteAccount(customerAccountId)
            .pipe(
                map(res => res.Success)
            );
    }

    export(filter: CustomersAccountsFilter): Observable<ExportFile> {
        return this.api.export(filter)
            .pipe(
                map(res => res.data)
            );
    }
}