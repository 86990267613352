import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';
import { star, starOrder } from '../stars/stars.models';

export interface category {
    id: string;
    name: string;
    nameAR: string;
    parentId?: any;
}

export interface categoryupdate {
    parentId: string;
    name: string;
    nameAR: string;
}

export interface categoryOrder {
    mainCategoryId: number|null;
    orderedCategories: OrderedCategory[];
}
export interface OrderedCategory {
    categoryId: number;
    order: number;
}


export interface categoryFeatureOrder {
    orderedStars: starOrder[];
}


export interface categoryFilter extends PageFilter {
    name?: string;
    nameAR?: string;
    IsParent?:boolean;
    parentId?: string;

}


export abstract class categoryData {
    abstract getAll(filter: categoryFilter): Observable<PaginatedResult<category[]>>;
    abstract getById(categoryId: string): Observable<category>;
    abstract addCategory(category:categoryupdate): Observable<boolean>;
    abstract updateCategory(categoryId: string,category:categoryupdate): Observable<boolean>;
    abstract deleteCategory(categoryId: number): Observable<boolean>;
    abstract setCategoriesOrder(categoryOrder: categoryOrder): Observable<boolean>;
    
    abstract getCategoryFeaturedStars(categoryId: string,filter: categoryFilter): Observable<star[]>;
    abstract setCategoriesStarsOrder(categoryId: number,starsOrder: categoryFeatureOrder): Observable<boolean>;



 }