import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ConfirmationDialogComponent } from '../../@components/confirmation-dialog/confirmation-dialog.component';


@Injectable()
export class CustomDialogService {

    constructor(private dialogService: NbDialogService) {
    }

    confirm(body: string, title?: string, okText?: string, cancelText?: string) {
        return this.dialogService.open(ConfirmationDialogComponent,
            {
                context: {
                    model: { body: body, title: title, okText: okText, cancelText: cancelText }
                }
            })
            .onClose;
    }
}