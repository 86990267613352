import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

export interface reviews {
    id: number;
    fullName: string;
    email: string;
    mobile: string;
    isActive:boolean;
}

export interface review{
    reviewId: number;
    reviewerUserName: string;
    starName: string;
    reviewRate: string;
    reviewComment: string;
    reviewStatus: string;
    reviewDate: Date;
}

 


export interface reviewsFilter extends PageFilter {
    UserName?: string;
    StarName?:string;
    ReviewStatus?:number;
    

}


export abstract class reviewsData {
     
    abstract getreviewss(filter: reviewsFilter): Observable<PaginatedResult<reviews[]>>;
    abstract changeStatus(reviewsId: string,data:any): Observable<boolean>;
    abstract getreview(id:number): Observable<review>;




 }