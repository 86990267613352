import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

export interface usersreport {
    userName: string;
    email: string;
    requestsCount: string;
    mobile: number;
    totalPayment: number;
 }

 


export interface usersreportFilter extends PageFilter {
    FromDate?: string;
    ToDate?:string;
    AccountId?:string;
    PageNumber?:string;
    PageSize?:string;

}


export abstract class usersreportData {
     
    abstract getusersreports(filter: usersreportFilter): Observable<PaginatedResult<usersreport[]>>;
    abstract export(filter: usersreportFilter) : Observable<usersreport[]>;


 }