import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExportFile, GenericResponse } from '../../../interfaces/common/server-response';
import { PermissionCategoriesListItem } from '../../../interfaces/Lookup/lookup.models';
import { EndPointsConfig } from '../endpoints.config';
import { HttpService } from './http.service';

@Injectable()

export class lookupApi{
    private readonly apiController: string = EndPointsConfig.LOOKUPS.CONTROLLER;

    constructor(private api: HttpService) { }

    getPermissionsCategories(): Observable<GenericResponse<PermissionCategoriesListItem[]>> {
        return this.api.get(`${this.apiController}/${EndPointsConfig.LOOKUPS.ENDPOINTS.PermissionsCategories}`, {});
    }

}