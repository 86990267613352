import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxValidationMessageComponent } from './validation-message/validation-message.component';
import { NgxFilterByNumberComponent } from './custom-smart-table-components/filter-by-number/filter-by-number.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NbActionsModule, NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

const COMPONENTS = [
  NgxValidationMessageComponent,
  NgxFilterByNumberComponent,
  ConfirmationDialogComponent,
  CustomTableComponent
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NbCardModule,
    NbButtonModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbIconModule
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS, ConfirmationDialogComponent, CustomTableComponent],
  entryComponents: [
    NgxFilterByNumberComponent,
  ],
})
export class ComponentsModule {
}
