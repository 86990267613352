import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ConfirmationDialog } from '../../@core/interfaces/common/confirmation-dialog';

@Component({
  selector: 'ngx-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  @Input() model: ConfirmationDialog;

  constructor(protected ref: NbDialogRef<ConfirmationDialogComponent>) { }

  cancel() {
    this.ref.close(false);
  }

  ok() {
    this.ref.close(true);
  }
}
