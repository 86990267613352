import { COSMIC_THEME } from './../../../../@theme/styles/theme.cosmic';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { star, starData, starFilter, starupdate } from '../../../interfaces/stars/stars.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';


@Injectable()
export class starService extends starData {
  
  


    private readonly apiController: string = EndPointsConfig.STAR.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }

    getAll(filter: starFilter): Observable<PaginatedResult<star[]>> {

        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );

    }



    getById(starId: string): Observable<star> {
        return this.api.get(`${this.apiController}/${starId}`)
            .pipe(
                map(res => res.data)
            )
    }

    updateStar(starId: number, star: starupdate): Observable<boolean> {
       
        return this.api.put(`${this.apiController}/${starId}`, this.mapToFormData(star)).pipe(
            map(res => res.Success)
        );
    }
     
    addstar(star: starupdate): Observable<boolean> {
    

        return this.api.post(`${this.apiController}`,this.mapToFormData(star)).pipe(
            map(res => res.Success)
        );
    }

    deletestar(starId: number): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${starId}`, {}).pipe(
            map(res => res.Success)
        );
    }

    mapToFormData(star:any){
        let form_data = new FormData();
        for (var key in star) {
            if (key == "account") {
                for (var item in star.account) {
                    form_data.append(key + '.' + item, star.account[item]);

                }
            }
            else if(key=="CategoryIds"){
                star[key].forEach(category => {
                    form_data.append(key , category);

                });

            }
             else {
                form_data.append(key, star[key]);

            }
        }

        return form_data;
 
    }

    



}