import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

export interface requestsreport { requestId: number;
    requestDate: Date;
    responseDate?: Date;
    starName: string;
    requesterUserName: string;
    requesterEmail: string;
    requesterMobile: string;
    requestStatus: string;
    requestPrice: number;
    reviewRate: number;
    reviewComment: string;
 }

 


export interface requestsreportFilter extends PageFilter {
    FromDate?: string;
    ToDate?:string;
    AccountId?:string;
    RequestStatus?:string;
    RequestId?:string;
    UserName?:string;
    StarId?:string;
    PageNumber?:string;
    PageSize?:string;

}


export abstract class requestsreportData {
     
    abstract getrequestsreports(filter: requestsreportFilter): Observable<PaginatedResult<requestsreport[]>>;
    abstract export(filter: requestsreportFilter) : Observable<requestsreport[]>;


 }