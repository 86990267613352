export abstract class SmartTableData {
  abstract getData(): any[];
}

export interface SmartTableSettings {
  actions: SmartTableActions;
  pager: SmartTablePager;
  columns: any;  //Dictionary<SmartTableColumnValue>;
  add: any;
  edit: any;
  delete: any;
}

export interface SmartTableActions {
  custom: SmartTableActionObject[];
  add: boolean;
  edit: boolean;
  delete: boolean;
  position: string;
}

export interface SmartTableCustomAction {
  name: string;
  tooltip: string;
  icon: string;
}

export interface SmartTableActionObject {
  name: string;
  title: string;
}

export interface SmartTablePager {
  display: boolean;
  perPage:number;
}

export interface SmartTableColumnValue {
  title: string;
  type: string;
  filter: boolean;
}

export interface SmartTableCustomColumnObject {
  responsePropName: string;
  type?: string;
  filter?: boolean;
  title?: string;
  editor?:any;
  valuePrepareFunction?:any;
}