import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { ExportFile, PaginatedResult } from '../common/server-response';

export interface CustomersAccountsListItem {
    Id: number;
    Name: string;
    Email: string;
    NationalNumber: string;
    Number: number;
    IsActive: boolean;
    IsDeleted: boolean;
}


export interface CustomerAccountDetails {
    Id: number;
    Name: string;
    Email: string;
    NationalNumber: string;
    Number: number;
    Address: string;
    IsActive: boolean;
    IsDeleted: boolean;
    IDs: CustomerIDs
}

export interface CustomerIDs {
    IDFrontBase64: string;
    IDBackBase64: string;
}

export interface CustomersAccountsFilter extends PageFilter {
    name?: string;
    email?: string;
    mobile?: string;
    nationalNumber?: string;
}


export abstract class CustomerAccountData {
    abstract getAll(filter: CustomersAccountsFilter): Observable<PaginatedResult<CustomersAccountsListItem>>;
    abstract getById(customerAccountId: string): Observable<CustomerAccountDetails>;
    abstract changeStatus(customerAccountId: string): Observable<boolean>;
    abstract deleteAccount(customerAccountId: string): Observable<boolean>;
    abstract export(filter: CustomersAccountsFilter): Observable<ExportFile>;
}