import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { starsreport, starsreportData, starsreportFilter } from '../../../interfaces/starsreport/starsreport.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';


@Injectable()
export class starsreportService extends starsreportData {
   
   

    
    private readonly apiController: string =  EndPointsConfig.reports.CONTROLLER +'/'+EndPointsConfig.reports.ENDPOINTS.stars.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }
    export(filter: starsreportFilter): Observable<starsreport[]> {
        return this.api.get(`${this.apiController}/${EndPointsConfig.reports.ENDPOINTS.stars.SUB.EXPORT}`, { params: filter }).pipe(
            map(res => res.data),
        );
    
    }


    getstarsreports(filter: starsreportFilter): Observable<PaginatedResult<starsreport[]>> {
        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );
    }

    getById(starsreportId: string): Observable<starsreport> {
        return this.api.get(`${this.apiController}/${starsreportId}`)
            .pipe(
                map(res => res.data)
            )
    }

    









}