import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExportFile, GenericResponse, PaginatedResult } from '../../../interfaces/common/server-response';
import { CustomerAccountDetails, CustomersAccountsFilter, CustomersAccountsListItem } from '../../../interfaces/customer-account/customer-account.models';
import { EndPointsConfig } from '../endpoints.config';
import { HttpService } from './http.service';

@Injectable()
export class CustomerAccountApi {

    private readonly apiController: string = EndPointsConfig.CUSTOMER_ACCOUNT.CONTROLLER;

    constructor(private api: HttpService) { }

    getAll(filter: CustomersAccountsFilter): Observable<GenericResponse<PaginatedResult<CustomersAccountsListItem>>> {
        return this.api.get(`${this.apiController}/${EndPointsConfig.CUSTOMER_ACCOUNT.ENDPOINTS.GETALL}`, { params: filter });
    }

    getById(customerAccountId: string): Observable<GenericResponse<CustomerAccountDetails>> {
        return this.api.get(`${this.apiController}/${EndPointsConfig.CUSTOMER_ACCOUNT.ENDPOINTS.GET_BY_ID}/${customerAccountId}`)
    }

    changeStatus(customerAccountId: string): Observable<GenericResponse<boolean>> {
        return this.api.put(`${this.apiController}/${EndPointsConfig.CUSTOMER_ACCOUNT.ENDPOINTS.CHANGE_STATUS}/${customerAccountId}`, {});
    }

    deleteAccount(customerAccountId: string): Observable<GenericResponse<boolean>> {
        return this.api.put(`${this.apiController}/${EndPointsConfig.CUSTOMER_ACCOUNT.ENDPOINTS.DELETE_ACCOUNT}/${customerAccountId}`, {});
    }

    export(filter: CustomersAccountsFilter): Observable<GenericResponse<ExportFile>> {
        return this.api.get(`${this.apiController}/${EndPointsConfig.CUSTOMER_ACCOUNT.ENDPOINTS.EXPORT}`, { params: filter });
    }

}