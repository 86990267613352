import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { lookupData, PermissionCategoriesListItem } from '../../../interfaces/Lookup/lookup.models';
import { lookupApi } from '../api/lookup.api';


@Injectable()
export class lookupService extends lookupData{
    constructor(private api: lookupApi) {
        super();
    }

    getPermissionsCategories(): Observable<PermissionCategoriesListItem[]> {
        return this.api.getPermissionsCategories()
        .pipe(
            map(res => res.data),
        );
    }


}