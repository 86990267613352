import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { review, reviews, reviewsData, reviewsFilter } from '../../../interfaces/reviews/reviews.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';


@Injectable()
export class reviewsService extends reviewsData {
    


    private readonly apiController: string = EndPointsConfig.REVIEWS.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }



    getreviewss(filter: reviewsFilter): Observable<PaginatedResult<reviews[]>> {
        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );
    }

    getById(reviewsId: string): Observable<reviews> {
        return this.api.get(`${this.apiController}/${reviewsId}`)
            .pipe(
                map(res => res.data)
            )
    }


    getreview(reviewsId: number): Observable<review> {
        return this.api.get(`${this.apiController}/${reviewsId}`)
        .pipe(
            map(res => res.data)
        )
    }
   
    changeStatus(reviewsId: string,data:any): Observable<boolean> {
        return this.api.put(`${this.apiController}/${reviewsId}/${EndPointsConfig.REVIEWS.SUB.UPDATE}`,data)
        .pipe(
            map(res => res.data)
        )  
    
    }










}