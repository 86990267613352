import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { request, requests, requestsData, requestsFilter } from '../../../interfaces/requests/requests.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';


@Injectable()
export class requestsService extends requestsData {
    


    private readonly apiController: string = EndPointsConfig.requests.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }



    getrequestss(filter: requestsFilter): Observable<PaginatedResult<requests[]>> {
        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );
    }

    getById(requestsId: string): Observable<requests> {
        return this.api.get(`${this.apiController}/${requestsId}`)
            .pipe(
                map(res => res.data)
            )
    }


    getrequest(requestsId: number): Observable<request> {
        return this.api.get(`${this.apiController}/${requestsId}`)
        .pipe(
            map(res => res.data)
        )
    }
   
    changeStatus(requestsId: string,data:any): Observable<boolean> {
        return this.api.put(`${this.apiController}/${requestsId}/${EndPointsConfig.requests.SUB.UPDATE}`,data)
        .pipe(
            map(res => res.data)
        )  
    
    }










}