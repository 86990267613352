export class EndPointsConfig {

    public static get COMMUNICATION_LOG() {
        return {
            CONTROLLER: 'communicationlogs',
            ENDPOINTS: {
                GETALL: 'getall',
                GETBYID: 'getbyid',
                GETBYEXCEPTIONID: 'getbyexceptionid',
                GETEXCEPTION: 'Getexceptionbyid',
                EXPORT: 'export'
            }
        };
    }
    public static get CATEGORY() {
        return {
            CONTROLLER: 'categories',
            ENDPOINTS: {
                order: 'order',
                featured: 'featured'

            }
        };
    }

    public static get REVIEWS() {
        return {
            CONTROLLER: 'reviews',
            SUB: {
                UPDATE: 'update-status'
            }

        }
    }

    public static get requests() {
        return {
            CONTROLLER: 'requests',
            SUB: {
                UPDATE: 'update-status'
            }

        }
    }
    public static get STAR() {
        return {
            CONTROLLER: 'stars',
            ENDPOINTS: {
                accounts: 'accounts/add-star',

            }
        };
    }

    public static get accounts() {
        return {
            CONTROLLER: 'accounts',
            ENDPOINTS: {
                'changeStatus': 'change-status'

            }
        };
    }


    public static get reports() {
        return {
            CONTROLLER: 'reports',
            ENDPOINTS: {
                stars: {
                    CONTROLLER: 'stars',
                    SUB: {
                        EXPORT: 'export'
                    }
                },
                users: {
                    CONTROLLER: 'users',
                    SUB: {
                        EXPORT: 'export'
                    }
                },
                requests: {
                    CONTROLLER: 'requests',
                    SUB: {
                        EXPORT: 'export'
                    }
                }
                

            }
        };
    }


    public static get COUNTRY() {
        return {
            CONTROLLER: 'countries'
        };
    }
    public static get CUSTOMER_ACCOUNT() {
        return {
            CONTROLLER: 'customersaccounts',
            ENDPOINTS: {
                GETALL: 'getall',
                GET_BY_ID: 'getbyId',
                CHANGE_STATUS: 'changestatus',
                DELETE_ACCOUNT: 'delete',
                EXPORT: 'export'
            }
        };
    }

    public static get CONF_PARAMETERS() {
        return {
            CONTROLLER: 'ConfParameters',
            ENDPOINTS: {
                GETALL: 'getall',
                GET_BY_ID: 'getbyId',
                ADD: 'Add',
                UPDATE: 'Update',
                DELETE: 'Delete',
                EXPORT: 'export'
            }
        };
    }
    public static get TRANSACTIONS() {
        return {
            ENDPOINTS: {
                GETALLTRANSACTIONS: 'getalltransactions',
                GETUSERTRANSACTIONS: 'getcustomertransactions'
            }
        };
    }
    public static get DYNAMIC_ROLES() {
        return {
            CONTROLLER: 'DynamicRoles',
            ENDPOINTS: {
                GetAllAdminUsers: 'getalladminusers',
                GetAllRoles: 'getallroles',
                GetRoleDetails: 'getroledetails',
                GetAllPermissions: 'getallpermissions',
                GetPermissionDetails: 'getpermissiondetails',
                EXPORT: 'export',
                ChangeAdminUserStatus: 'changeuserstatus',
                AddAdminUser: 'AddAdminUser',
                DeleteUser: 'deleteuser',
                UpdateUserRole: 'updateuserrole',
                DeleteRole: 'deleterole',
                UpdateRole: 'updaterole',
                AddRole: 'addrole',
                GetCategorizedPermissions: 'getcategorizedpermissions'
            }
        };
    }

    public static get LOOKUPS() {
        return {
            CONTROLLER: 'lookups',
            ENDPOINTS: {
                GetLookup: 'api/GetLookup',
                PermissionsCategories: 'PermissionCategories'
            }
        }
    }


}