 import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

export interface account {
    id: number;
    fullName: string;
    email: string;
    mobile: string;
    isActive:boolean;
}

 


export interface accountFilter extends PageFilter {
    Email?: string;
    mobile?:string;
    name?:string;

}


export abstract class accountData {
     
    abstract getAccounts(filter: accountFilter): Observable<PaginatedResult<account[]>>;
    abstract changeStatus(AccountId: string): Observable<boolean>;


 }