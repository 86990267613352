import { Observable } from 'rxjs';
 import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

 
  

export interface country {
    id: string;
    name: string;
    nameAR: string;
}
 
export interface countryupdate {
    name: string;
   nameAR: string;
}



export interface countryFilter extends PageFilter {
   name?: string;
   nameAR?: string;
   IsParent?:boolean;
   parentId?: string;

}


export abstract class countriesData {
 
    abstract getCountries(): Observable<country[]>;

    abstract getAll(filter: countryFilter): Observable<PaginatedResult<country[]>>;
    abstract getById(countryId: string): Observable<country>;
    abstract addcountry(country:countryupdate): Observable<boolean>;
    abstract updatecountry(countryId: string,country:countryupdate): Observable<boolean>;
    abstract deletecountry(countryId: number): Observable<boolean>;
     
  


 }
