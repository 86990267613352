import { NbAuthOAuth2JWTToken, NbPasswordAuthStrategy } from '@nebular/auth'; //NbAuthJWTToken,
import { environment } from '../../environments/environment';

export const authOptions = {
  strategies: [
    NbPasswordAuthStrategy.setup({
      name: 'email',
      baseEndpoint: environment.apiUrl,
      token: {
        class: NbAuthOAuth2JWTToken, //NbAuthJWTToken,
        key: 'data.token'
      },
      login: {
        endpoint: 'auth/login',
        method: 'post',
        redirect: {
          success: '/',
          failure: null, // stay on the same page
        }
      },
      logout: {
        endpoint:null,
        method: 'post',
        redirect: {
          success: '/auth/login',
          failure: null, // stay on the same page
        }
      },
      requestPass: {
        endpoint: 'auth/forgot-password',
        method: 'post',
      },
      resetPass: {
        endpoint: 'auth/reset-password',
        method: 'post',
      },
      refreshToken: {
        endpoint: 'auth/refresh-token',
        method: 'post',
      },
    }),
  ],
  forms: {
    login: {
    },
    validation: {
      fullName: {
        required: true,
        minLength: 6,
        maxLength: 20,
      },
    },
  } 
};
