import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { account, accountData, accountFilter } from '../../../interfaces/accounts/accounts.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';


@Injectable()
export class accountService extends accountData {
   


    private readonly apiController: string = EndPointsConfig.accounts.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }



    getAccounts(filter: accountFilter): Observable<PaginatedResult<account[]>> {
        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );
    }

    getById(accountId: string): Observable<account> {
        return this.api.get(`${this.apiController}/${accountId}`)
            .pipe(
                map(res => res.data)
            )
    }

    changeStatus(AccountId: string): Observable<boolean> {
        return this.api.put(`${this.apiController}/${AccountId}/${EndPointsConfig.accounts.ENDPOINTS.changeStatus}`,'')
        .pipe(
            map(res => res.data)
        )    }










}