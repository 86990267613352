import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExportFile, PaginatedResult } from '../../../interfaces/common/server-response';
import { usersreport, usersreportData, usersreportFilter } from '../../../interfaces/usersreport/usersreport.models';
import { HttpService } from '../api/http.service';
import { EndPointsConfig } from '../endpoints.config';


@Injectable()
export class usersreportService extends usersreportData {
   
   

    
    private readonly apiController: string =  EndPointsConfig.reports.CONTROLLER +'/'+EndPointsConfig.reports.ENDPOINTS.users.CONTROLLER;

    constructor(private api: HttpService) {
        super();
    }
    export(filter: usersreportFilter): Observable<usersreport[]> {
        return this.api.get(`${this.apiController}/${EndPointsConfig.reports.ENDPOINTS.users.SUB.EXPORT}`, { params: filter }).pipe(
            map(res => res.data),
        );
    
    }


    getusersreports(filter: usersreportFilter): Observable<PaginatedResult<usersreport[]>> {
        return this.api.get(`${this.apiController}`, { params: filter }).pipe(
            map(res => res.data),
        );
    }

    getById(usersreportId: string): Observable<usersreport> {
        return this.api.get(`${this.apiController}/${usersreportId}`)
            .pipe(
                map(res => res.data)
            )
    }

    









}