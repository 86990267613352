import { Observable } from 'rxjs';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

export interface requests {
    id: number;
    starName: string;
    categoriesNames: string[];
    requestDate: Date;
    requestTitle: string;
    answerDate?: Date;
    requestStatus: number;
    requestStatusName: string;
    aging: number;
    isAnswered: boolean;
    isReviewed: boolean;
}

export interface request {
    id: number;
    starName: string;
    categoriesNames: string[];
    requestDate: Date;
    requestTitle: string;
    answerDate: Date;
    requestStatusName: string;
    aging: number;
    requesterName: string;
    price: number;
    requestTypeName: string;
    requestDescription: string;
    requestMediaFilesUrls:string[];
}




export interface requestsFilter extends PageFilter {
    Requester?: string;
    StarName?: string;
    RequestStatus?: number;
    RequestDate?: string;
    AnswerDate?: string;
    HasReview?: boolean;
    Keyword?: string;




}


export abstract class requestsData {

    abstract getrequestss(filter: requestsFilter): Observable<PaginatedResult<requests[]>>;
    abstract changeStatus(requestsId: string, data: any): Observable<boolean>;
    abstract getrequest(id: number): Observable<request>;




}