import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { SmartTableHelper } from '../../@core/helpers/smart-table.helper';
import { CustomTable } from '../../@core/interfaces/common/custom-table';

@Component({
  selector: 'ngx-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit {

  @Input() model: CustomTable;
  @Output() export = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() customAction = new EventEmitter();
  @Output() select = new EventEmitter();

  source: ServerDataSource;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    console.log(this.model);
    this.source = new ServerDataSource(this.http, this.model.serverSourceConfig);
  }

  onCustomAction(event) {
    this.customAction.emit(event);
  }

  onExport() {
    this.export.emit();
  }

  onAdd() {
    this.add.emit();
  }
  selectRow(event){
    this.select.emit(event);
  }
}
