import { Observable } from 'rxjs';
import { category } from '../categories/categories.models';
import { PageFilter } from '../common/page-filter';
import { PaginatedResult } from '../common/server-response';

export interface star {
    id: number;
    fullName: string;
    fullNameAR: string;
    intro: string;
    introAr: string;
    price: number;
    countryId: number;
    countryName: string;
    profilePicture: string;
    categories: category[];
    fbURL?: string;
    twitterURL?: string;
    instagramURL?: string;
    isFeatured?: boolean;
    order?: number;
    introVideoExternalUrl?: string;
    introVideoEmbed?: string;
    introVideoUploadedUrl?: string;
    accountManagerEmail?: string;
    accountManagerName?: string;
    email?:string,
    mobile?: string,
    addressLine1?: string,
    addressLine2?: string,
    notes?: string

}

export interface starupdate {
    account?: formAccount,
    FullName: string,
    FullNameAR: string,
    Intro: string,
    introAR: string,
    price: string,
    categoryIds: number[],
    countryId: number,
    profilePicture: string,
    FbURL?: string;
    TwitterURL?: string;
    InstagramURL?: string;
    introVideoExternalUrl?: string;
    introVideoEmbed?: string;
    introVideoUploadedUrl?: string;
}
export interface starOrder {
    starId: number,
    order: number
}
export interface formAccount {
    linkedAccountId?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
}


export interface Orderedstar {
    starId: number;
    order: number;
}




export interface starFilter extends PageFilter {
    FullName?: string;
    FullNameAR?: string;
    PriceFrom?: number;
    PriceTo?: number;

}


export abstract class starData {
    abstract getAll(filter: starFilter): Observable<PaginatedResult<star[]>>;
    abstract getById(starId: string): Observable<star>;
    abstract addstar(star: starupdate): Observable<boolean>;
    abstract deletestar(starId: number): Observable<boolean>;
    abstract updateStar(starId: number, star: starupdate): Observable<boolean>;


}